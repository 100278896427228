<template>
  <!-- 横屏 -->
  <div class="v-game-index">
    <horizontalScreen > 
      <!-- <scaleWrap> -->
        <div class="home">
          
          <audio id="myAudio" preload="auto"></audio>
          <click-audio ref="clickAudio"></click-audio>
          <img class="btn-back" @click="backHandler" src="@/assets/index/btn-back.png"/>
          <div class="btn-book" :style="{backgroundImage: 'url(' + albumNameBg + ')'}"></div>
        </div>
      <!-- </scaleWrap> -->
    </horizontalScreen>
    <div class="word-container">
      <!-- <div class="item" :class="`scroll-${index}`" @click.stop.prevent="wordClickHandler($event, item, index)" v-for="(item, index) in showWordList" :key="index">
        <div class="left">{{item.gradeName}}</div>
        <div class="right">
          <span>{{item.fontName}}</span>
        </div>
      </div> -->
      <table>
        <tr v-for="(item, index) in showWordList2" :key="index">
          <td @click.stop.prevent="isEmpty(inner) ? null : wordClickHandler($event, inner, inner.index -1)" v-for="(inner, innerIndex) in item" :key="innerIndex">
            <template v-if="!isEmpty(inner)">第{{inner.index}}课</template>
          </td>
        </tr>
      </table>
      
      </div>
  </div>
</template>
<script>
import horizontalScreen from '@/components/horizontal-screen'
import {isEmpty, chunk} from 'lodash'
import ClickAudio from '@/components/clickAudio.vue'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'GameIndex',
  components: {
    horizontalScreen, ClickAudio
  },
  watch: {
    '$route': {
      immediate: true,
      // deep: true,
      handler() {
        this.$nextTick(() => {
          console.log('routescrollTop', this.scrollTop)
            const options = {
              container: '.word-container',
              easing: 'ease-in',
              offset: this.scrollTop,
              force: true,
              cancelable: true
            }
            VueScrollTo.scrollTo(`.word-container`, 0, options)
          
        })
      }
    }
  },
  computed: {
    ...mapGetters(['albumName', 'albumId', 'scrollTop']),
    albumNameBg() {
      return require(`@/assets/game/${this.albumId}.png`)
    },
    showWordList2() {
      let res = chunk(this.showWordList, 5)
      let len = res.length
      for(let i = 0; i<len;i++) {
        for(let j=0;j<res[i].length;j++) {
          if (res[i].length< 5) {
            res[i].push({})
          }
        }
      }
      if (res.length < 4) {
        for(let i = 0; i<4-len;i++) {
          res.push([{},{},{},{},{}])
        }
      }
      console.log('res', res)
      return res
    }
  },
  mounted() {
    if(document.querySelector('#app section').style.transform === 'rotate(0deg)') {
      // 横屏  word-container
      document.querySelector('.word-container').classList.add('word-container-horizontal')
    }
  },
  data() {
    return {
      type: Number(this.$route.query.type),
      showWordList: []
    }
  },
  created(){
    this.fetchList()
  },
  methods: {
    isEmpty,
    ...mapActions(['getGamesList']),
    ...mapMutations(['SET_SCROLL_TOP', 'SET_GAME_ROUTER']),
    async fetchList() {
      this.$loading.show('数据加载中。。。')
      try {
        const res = await this.getGamesList({
          albumId: this.albumId,
          current: 1,
          pageSize: 20
        })
        console.log('getGamesList', res)
        // this.bookName = res.albumName
        this.showWordList = res.result.map((item, index) => {
          return {
            ...item,
            index: index + 1
          }
        })

      } finally {
        this.$loading.hide()
      }
    },
    async backHandler() {
      await this.$refs.clickAudio.playAudio()
      this.$router.push({name: 'Book'})

    },
    // 字点击
    wordClickHandler(event, word, index) {
      let objMap = new Map([
        [1, () => {
          return {
            0: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            1: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            2: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            3: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            4: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            5: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            6: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            7: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            },
            8: () => {
              return {
                name: 'Game46',
                router: 'game4-10'
              }
            }
          }
        }],
        [2, () => {
          return {
            0: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            1: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            2: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            3: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            4: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            5: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            6: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            7: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            8: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            9: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            10: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            },
            11: () => {
              return {
                name: 'Game54',
                router: 'game5-1'
              }
            }
          }
        }],
        [3, () => {
          return {
            0: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            1: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            2: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            3: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            4: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            5: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            6: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            7: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            8: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            9: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            10: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            11: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            12:  () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            13:  () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            },
            14: () => {
              return {
                name: 'Game64',
                router: 'game5-11'
              }
            }
          }
        }],
        [4, () => {
          return {
            0: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            1: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            2: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            3: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            4: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            5: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            6: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            7: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            8: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            9: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            10: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            11: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            12: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            13: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            14: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            15: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
            16: () => {
              return {
                name: 'Game73',
                router: 'game6-3'
              }
            },
          }
        }],
        [5, () => {
          return {
            0: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            1: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            2: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            3: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            4: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            5: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            6: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            7: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            8: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            9: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            10: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            11: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            12: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            13: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            14: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            15: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            },
            16: () => {
              return {
                name: 'Game81',
                router: 'game6-11'
              }
            }
          }
        }],
        [6, () => {
          return {
            0: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            1: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            2: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            3: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            4: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            5: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            6: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            7: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            8: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            9: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            10: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            11: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            12: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            13: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            },
            14: () => {
              return {
                name: 'Game80',
                router: 'game6-10'
              }
            }
          }
        }]
      ])
      const scrollTop = document.querySelector('.word-container').scrollTop
      this.SET_SCROLL_TOP(scrollTop)
      console.log('SET_SCROLL_TOP', scrollTop)
      const game = objMap.get(this.albumId)()[index]()
      console.log('game', game, game.name, game.router)
      const gameRouter = game.router
      this.SET_GAME_ROUTER(gameRouter)
      this.$router.push({name: `${game.name}`, query: {index: index + 1, id: word.gradeId}})
    }
  }
}
</script>

<style lang="scss" scoped>
.v-game-index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  table {
    width: 100%;
    height: 100%;
    border: solid 1px #e78d02;
    border-collapse: collapse;
  }
  tr,td {
    border: solid 1px #e78d02;
    border-collapse: collapse;
    font-size: 20px;
    color: #000;
    height: 50px;
    font-weight: bolder;
  }
  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }
  .home {
    height: 100%;
    width: 100%;
    background: #fffde6;
    overflow: hidden;
  }
  .btn-book {
    border-radius: 8px;
    font-weight: bolder;
    color: #72350e;
    font-size: 28px;
    position: fixed;
    width: 190px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    background-position: center center; 
    background-repeat: no-repeat;  
    background-size: 100%;
  }
  .btn-back {
    color: #2f0000;
    position: fixed;
    width: 65.5px;
    right: 10PX;
    top: 5PX;
  }

  .word-container {
    -webkit-overflow-scrolling: touch; 
    // border: solid 1px #e78d02;
    box-sizing: border-box;
    position: absolute;
    overflow-x: scroll;
    height: 70%;
    bottom: 0;
    height: 60vw;
    width: 96vh;
    left: 76%;
    top: 50%;
    transform: translateX(-50%) rotate(90deg);
    transform-origin: top center;
    &-horizontal {
      transform: none;
      transform-origin: left center;
      top: 90px;
      left: 3vw;
      width: 94vw;
      height: 60vh;
    }
    .item { 
      color: #000;
      font-weight: bolder;
      display: flex;
      font-size: 20px;
      height: 50px;
      align-items: center;
      & + .item {
        border-top: solid 1px #e78d02;
      }
      .left {
        height: 100%;
        // padding: .7% 0;
        border-right: solid 1px #e78d02;
        width: 120px;
        text-align: center;
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right {
        display: flex;
        align-items: center;
        height: 100%;
        text-align: left;
        padding-left: 50px;
        // padding: .7% 0 .7% 24px;
        letter-spacing: 8px;
        flex: 1;
        > span {
          line-height: 24px;
        }
      }
    }
  }

}
</style>
